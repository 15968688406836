 <template>
  <div>
    <CCard>
      <CCardHeader class="font-weight-bold"> Add New Role </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CInput
              label="Role Name"
              v-model="role_name"
              placeholder="Enter Role Name"
            />
          </CCol>
        </CRow>

        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-left">Permission</th>
              <th scope="col" class="text-center">Create</th>
              <th scope="col" class="text-center">Edit</th>
              <th scope="col" class="text-center">View</th>
              <th scope="col" class="text-center">Delete</th>
            </tr>
          </thead>
          <tbody class="text-center pt-5" v-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">
                <CSpinner color="primary" size="lg" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(permission_group, key) in permissions" :key="key">
              <td>{{ key | capitalizeTheFirstLetterOfEachWord }}</td>
              <template v-for="term in crud_terms">
                <td class="text-center" :key="term">
                  <template v-for="permission in permission_group">
                    <input
                      type="checkbox"
                      :value="permission.id"
                      v-model="selectedPermissions"
                      v-if="permission.name.indexOf(term) > -1"
                      :key="permission.id"
                    />
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
        </table>

        <CButtonToolbar class="justify-content-between">
          <div class="justify-content-left">
            <CButton size="sm" color="danger" @click="clear_all()" class="mr-2"
              ><CIcon name="cil-ban" /> Reset</CButton
            >
            <CButton size="sm" color="info" @click="select_all()"> <CIcon name="cil-grid" /> Select All</CButton>
          </div>
          <CButton size="sm" color="success" v-if="!isSaving" @click="saveRole"
            ><CIcon name="cil-check-circle" /> Save Role</CButton
          >
          <CButton color="secondary" class="px-4" disabled v-else>
            <CSpinner grow size="sm" /> Saving..
          </CButton>
        </CButtonToolbar>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"; 
const crud_terms = ["create", "update", "read", "delete"];
export default {
  name: "RoleCreate", 
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("permissions/getPermissions", {
      Authorization: "bearer " + this.$store.state.access_token,
    });
  },
  computed: {
    ...mapGetters(["permissions/permissions"]),
    ...mapState({
      permissions: (state) => state.permissions.permissions,
      isLoading: (state) => state.permissions.isLoading,
      isSaving: (state) => state.permissions.isSaving,
    }),
  },
  data() {
    return {
      role_name: null,
      crud_terms,
      selectedPermissions: [],
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
    };
  },
 
  methods: {
    clear_all() {
      this.role_name = null;
      this.selectedPermissions = [];
    },
    select_all() {
      for (const group in this.permissions) {
        for (const permission in this.permissions[group]) {
          this.selectedPermissions.push(this.permissions[group][permission].id);
        }
      }
    },
    saveRole() {
      this.$store
        .dispatch("permissions/saveRole", {
          Authorization: "bearer " + this.$store.state.access_token,
          role_name: this.role_name,
          permissions: this.selectedPermissions,
        })
        .then((response) => {
          this.selectedPermissions = [];
          this.role_name = null;
        });
    },
  },
};
</script>